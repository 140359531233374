<template>
  <Component :is="components[componentType(item.type)]" v-for="(item, index) in componentsData"
             :key="`component-${index}`"
             :data="item" v-bind="$attrs"/>
</template>

<script setup>
import {componentType} from '@/helper/type.js'

defineProps({
  componentsData: {
    type: Array,
    default: () => []
  }
})

const Banner = defineAsyncComponent(() => import('@/components/dynamic_components/Banner/index'))
const GridBanner = defineAsyncComponent(() => import('@/components/dynamic_components/GridBanner.vue'))
const ProductListSection = defineAsyncComponent(() => import('@/components/dynamic_components/product-list-section-wrapper/index'))
const ScrollableBanners = defineAsyncComponent(() => import('@/components/dynamic_components/ScrollableBanners.vue'))
const TimerBanner = defineAsyncComponent(() => import('@/components/dynamic_components/TimerBanner.vue'))
const VideoSlider = defineAsyncComponent(() => import('@/components/dynamic_components/video-slider'))
const components = {
  Banner, GridBanner, ProductListSection, ScrollableBanners,
  TimerBanner, VideoSlider
}
</script>